.Contact {
  display: flex;
  gap: 2px;
  padding: 0 2rem;
}

.right-c {
  flex: 1 1;
  display: flex;
  padding: 2rem;
}
.right-c > img {
  max-width: 100%;
  height: auto;
}
.left-c {
  flex: 1 1;
  display: flex;
  padding: 2rem;
  flex-direction: column;
  color: white;
}

.left-c a {
  text-decoration: none;
  color: white;
  font-size: 24px;
}
.left-c h6 {
  font-size: 20px;
  color: var(--orange);
}
.left-c h4 {
  font-size: 32px;
}
.icons {
  display: flex;
  flex-direction: column;
}
.icons i {
  font-size: 24px;
  padding: 8px;
}

@media screen and (max-width: 900px) {
  .Contact {
    flex-direction: column;
  }
  .right-c {
    align-items: center;
    justify-content: center;
  }
  .right-c > img {
    max-width: 60%;
    height: auto;
  }
  .left-c {
    align-self: center;
    padding-top: 0;
  }
  .left-c a {
    font-size: 16px;
  }
  .left-c h6 {
    font-size: 15px;
    color: var(--orange);
  }
  .left-c h4 {
    font-size: 24px;
  }

  .icons i {
    font-size: 16px;
    padding: 8px;
  }
}
