.hero {
  display: flex;
  justify-content: space-between;
}
.left-h {
  padding: 2rem;
  padding-top: 1.5rem;
  flex: 3;
  display: flex;
  gap: 2rem;
  flex-direction: column;
}
.right-h {
  flex: 1;
  position: relative;
  background-color: var(--orange);
}
.the-best {
  margin-top: 4rem;
  background-color: #363d42;
  border-radius: 4rem;
  font-size: 1rem;
  width: fit-content;
  padding: 20px 13px;
  text-transform: uppercase;
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.the-best > span {
  z-index: 2;
}
.the-best > div {
  position: absolute;
  background-color: var(--orange);
  width: 5.4rem;
  height: 80%;
  left: 8px;
  border-radius: 3rem;
  z-index: 1;
}
.hero-text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: bold;
  color: white;
  text-overflow: inherit;
}
.hero-text > div:nth-of-type(3) {
  font-size: 1rem;
  font-weight: 200;
  text-transform: none;
  letter-spacing: 1px;
  width: 80%;
}
.figures {
  display: flex;
  gap: 2rem;
}
.figures > div {
  display: flex;
  flex-direction: column;
}
.figures > div > span:nth-of-type(1) {
  color: white;
  font-size: 2rem;
}
.figures > div > span:nth-of-type(2) {
  color: var(--gray);
  text-transform: uppercase;
}
.hero-buttons {
  display: flex;
  gap: 1rem;
  font-weight: normal;
  padding-top: 15px;
}
.hero-buttons > :nth-child(1),
.hero-buttons > :nth-child(2) {
  color: black;
  background-color: var(--orange);
  width: 9rem;
}
.hero-img {
  position: absolute;
  top: 6rem;
  right: 10rem;
  width: 17rem;
}
.contact-info {
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--darkGrey);
  width: fit-content;
  padding: 1.5rem;
  align-items: flex-start;
  border-radius: 5px;
  position: absolute;
  right: 3rem;
  top: 1rem;
  align-items: left;
}
.contact-info a {
  color: white;
  text-decoration: none;
}
.contact-info a:hover {
  color: var(--orange);
}
.contact-info i {
  font-size: 22px;
  padding-right: 5px;
}
.hero-blur {
  width: 22rem;
  height: 30rem;
  left: 0;
}
@media screen and (max-width: 900px) {
  .hero {
    flex-direction: column;
  }
  .hero-blur {
    width: 14rem;
  }
  .the-best {
    margin-top: 0;
    font-size: small;
    align-self: center;
    transform: scale(0.8);
  }
  .hero-text {
    font-size: 30px;
    align-items: center;
    justify-content: center;
  }
  .hero-text > div:nth-of-type(2) {
    font-size: 18px;
  }
  .hero-text > div:nth-of-type(3) {
    font-size: 15px;
    letter-spacing: 1px;
    text-align: center;
  }
  .hero-buttons {
    justify-content: center;
  }
  .figures {
    align-self: center;
  }
  .figures > div > span:nth-of-type(1) {
    font-size: 18px;
  }
  .figures > div > span:nth-of-type(2) {
    font-size: 12px;
  }
  .right-h {
    position: relative;
    background: none;
  }
  .contact-info {
    right: 0;
    top: -1rem;
    background: none;
    font-size: 15px;
  }

  .hero-img {
    position: relative;
    width: 12rem;
    left: 7rem;
    bottom: 0;
    align-self: center;
  }
}
