.Info {
  display: flex;
  gap: 2rem;
  padding: 0 2rem;
}

.left-i {
  flex: 1 1;
  display: flex;
  padding: 2rem;
}
.left-i > img {
  max-width: 100%;
  height: auto;
  padding: 5px;
  border-radius: 10px;
}
.right-i {
  flex: 1 1;
  display: flex;
  padding: 2rem;
  color: white;
  flex-direction: column;
}
.right-i > :nth-child(1) {
  font-size: 3rem;
}
.description {
  text-align: justify;
  font-size: 17px;
  letter-spacing: 0.5px;
  line-height: 1.5;
}
@media screen and (max-width: 900px) {
  .Info {
    flex-direction: column;
  }
  .left-i {
    justify-content: center;
    align-items: center;
  }
  .left-i > img {
    max-width: 60%;
  }
  .right-i {
    padding-bottom: 0;
  }
  .right-i > :nth-child(1) {
    font-size: 35px;
    align-self: center;
  }
  .description {
    font-size: 14px;
  }
}
