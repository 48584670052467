.Services {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 2rem;
}
.services-blur-1 {
  width: 32rem;
  height: 23rem;
  top: 60rem;
  left: 0rem;
}
.services-blur-2 {
  width: 32rem;
  height: 23rem;
  top: 80rem;
  right: 0rem;
}
.services-blur-3 {
  width: 32rem;
  height: 23rem;
  top: 120rem;
  left: 0rem;
}
.services-header {
  display: flex;
  gap: 2rem;
  font-weight: bold;
  font-size: 3rem;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-style: italic;
}
.services-categories {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}
.category {
  display: flex;
  flex-direction: column;
  background-color: rgba(128, 128, 128, 0.489);
  border-radius: 4px;
  padding: 2rem;
  gap: 1rem;
  color: white;
}
.category:hover {
  background: var(--planCard);
  cursor: pointer;
  color: rgba(0, 0, 0, 0.842);
}
.category > :nth-child(1) {
  font-size: 35px;
}
.category > :nth-child(2) {
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
}
.category > :nth-child(3) {
  font-size: 15px;
  line-height: 1.5;
  text-align: justify;
}

@media screen and (max-width: 900px) {
  .services-categories {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  .services-header {
    flex-direction: column;
    gap: 1rem;
    font-size: 25px;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
  }
}
@media screen and (max-width: 600px) {
  .services-header {
    font-size: 18px;
  }
}
