.Join {
  display: flex;
  padding: 0 2rem;
  gap: 10rem;
}
.left-j {
  color: white;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
}
.left-j > hr {
  position: absolute;
  width: 10.5rem;
  border: 2px solid var(--orange);
  border-radius: 20%;
  margin: -10px 0;
}
.join-blur-1 {
  width: 32rem;
  height: 23rem;
  bottom: 10rem;
  left: 0rem;
}
.join-blur-2 {
  width: 32rem;
  height: 23rem;
  bottom: 60rem;
  right: 0rem;
}
.left-j > div {
  display: flex;
  gap: 1rem;
}
.left-j > div > span > a {
  text-decoration: none;
  color: white;
}
.left-j > div > span > a:hover {
  color: var(--orange);
}
.right-j {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.email-container {
  display: flex;
  gap: 3rem;
  background-color: gray;
  padding: 1rem 2rem;
}
.email-container > input {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--lightgray);
}
::placeholder {
  color: var(--lightgray);
}
.btn-j {
  background-color: var(--orange);
  color: black;
}
@media screen and (max-width: 900px) {
  .Join {
    flex-direction: column;
    gap: 1rem;
  }
  .left-j {
    font-size: 18px;
    flex-direction: column;
  }
}
@media screen and (max-width: 650px) {
  .left-j {
    font-size: 14px;
    flex-direction: column;
  }
}
